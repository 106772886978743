import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button } from "../src/gatsby-theme-docz/components/Button/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "krankenhäuser"
    }}>{`Krankenhäuser`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Kunde`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Landeszentrum für Zell- und Gentherapie, Halle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,2 Mio€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bundeswehrkrankenhaus Wiederitzsch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`920 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bundeswehrkrankenhaus Wiederitzsch, 2.BA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,2 Mio€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Kreiskrankenhaus Grimma, 2.BA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`890 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`St. Georg Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helios-Klinik Borna – Funktionsneubau 1 mit OP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`665 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helios-Klinik Borna – Funktionsneubau 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`290 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helios-Klinik Borna – Haus C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helios-Klinik Borna – Brandschutzmaßnahme – BS- Ertüchtigungen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/Bau`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`250 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helios-Klinik Zwenkau – Haus 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`210 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Klinken Zschadraß, Haus 5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`115 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Psychatrische Klinik Zschadraß`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`175 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Kreiskrankenhaus Oschatz`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`85 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sächsische Kliniken Hubertusburg, Wermsdorf, Haus 63`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`130 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sächsische Kliniken Hubertusburg, Wermsdorf, Haus 60`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sächsische Kliniken Hubertusburg, Wermsdorf, Haus 88`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,1 Mio€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ärztehaus und Kindergarten Mutzschen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55 T€`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Legende: H=Heizung, S=Sanitär, L=Lüftungsinstallation, K=Kälte- bzw. Klimainstallationen, MSR=Regeltechnische Anlagen`}</strong></p>
    <Button mdxType="Button" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      